import React, {useState} from "react";
import { Paper, BottomNavigationAction, BottomNavigation, Container, Box, InputBase, Typography,  Divider, Button, Skeleton } from '@mui/material';
import TranscriptionCard from "./TranscriptionCard";
import { getPostHeaders } from './utils/headers';
import PolicyIcon from '@mui/icons-material/Policy';
import TwitterIcon from '@mui/icons-material/Twitter';

function Landing() {
    const [showTranscriptionCard, setShowTranscriptionCard] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    const [transcript, setTranscript] = useState("");

    
    async function fetchTranscript() {        
        let headers = getPostHeaders({videoUrl:videoUrl}, "POST");               
        const response = await fetch(`${process.env.REACT_APP_BE_BASE_URL}/api/summarize_video/`,
            {...headers})
        // if (response.status === 401) {context.logout({})}            
        const data = await response.json();                  
        setTranscript(data)  
        setShowLoading(false);
        setShowTranscriptionCard(true);
    }
    

    function handleSubmit(event) {
        event.preventDefault();        
        setShowLoading(true);
        fetchTranscript();          
    }

    const imgMax ={lg: '80%', md:'100%', xs:'100%', sm:'100%'}
    
    // if !showLoading && !showTranscriptionCard then return imgMax else return 25%
    const getMaxWidth = !showLoading && !showTranscriptionCard ? imgMax : '25%';


    return (  
        <Container>
            {!showLoading && !showTranscriptionCard &&     
                <>
                <Box sx={{ width: '100%', maxWidth: "fit-content", margin:"4%" }}>
                    <Typography variant="h1" gutterBottom sx={{ fontSize:"5em"}}>
                    Elevate Your Learning Game
                    </Typography>   
                </Box>        
                <Divider variant="middle" />               
                </>
            }
        
        <Box sx={{ width: '100%', maxWidth: "fit-content", marginLeft:"4%", margingRight:"4%", marginTop:"1%", marginBottom:"2%"}}>
            <Box
                component="img"
                sx={{
                height: 'auto',
                width: 1024,
                maxHeight: { xs: 'auto', md: 'auto' },
                maxWidth: getMaxWidth,
                }}
                alt=""
                src="./HeyHeyAITextArrowTransparent.png"
            />
            {!showLoading && !showTranscriptionCard &&   
                <Typography variant="h2" gutterBottom sx={{ fontSize:"3.35em"}}>
                    transform YouTube Videos into Bite-sized learning nuggets                                                       
                </Typography>                                         
            }
        </Box>  
        <Divider variant="middle" sx={{marginBottom:"4%"}}/>
        {!showLoading && !showTranscriptionCard &&     
            <Box component="div" sx={{margin:'auto', width: '100%', maxWidth: "fit-content"}}>
                <Box component="div" sx={{margin:'auto', width: '100%', maxWidth: "fit-content"}}>
                    <Typography variant="body1" gutterBottom>
                        Which video do you want to learn from today?                
                    </Typography>                   
                </Box>
                <Box component="form" onSubmit={handleSubmit} alignContent="center" sx={{ width: '100%', maxWidth: "fit-content", margin:"auto"}}>                   
                        {/* https://youtu.be/jeynvy5yNoE */}
                    <InputBase                   
                        value={videoUrl} 
                        onChange={(e)=>setVideoUrl(e.target.value)}
                        sx={{ ml: 1, flex: 1, display: 'block', outlineStyle: 'solid', outlineColor:"gray", width: {sm:'auto', md:'auto', lg: '30ch'}, padding: '1rem', fontSize: '2rem' }}
                        placeholder="https://youtu.be/OA23CSoQM_U"
                        inputProps={{ 'aria-label': 'YouTube URL' }}
                    />
                    <Box component="div" sx={{margin:'auto', width: '100%', maxWidth: "fit-content"}}>
                        <Button size="large" variant="contained" 
                            type="submit"
                            // onClick={()=>handleShowTranscriptionCard()}
                            sx={{
                                width: 300,
                                fontSize: '2rem',
                                borderRadius:7,
                                marginTop: '5%',
                                marginBottom: '25%',
                                backgroundColor: '#cc181e',
                                }} >
                                    Let's Go
                        </Button>           
                    </Box>
                </Box>
            </Box>
        }
        <Box sx={{ width: '100%', maxWidth: "fit-content", margin:"4%" }}>
                    
        </Box>
        <Box sx={{ width: '100%', maxWidth: "fit-content", margin:"4%" }}>
            {showLoading &&
                 <Skeleton variant="rectangular" width={750} height={750}  />
            }
            {showTranscriptionCard &&                 
                <TranscriptionCard videoUrl={videoUrl} transcript={transcript}/>
            }
        </Box>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation showLabels sx={{backgroundColor:'#cc181e'}}>
            <BottomNavigationAction sx={{color:'white'}} label="Privacy" icon={<PolicyIcon />} />
            <BottomNavigationAction sx={{color:'white'}} label="Twitter" icon={<TwitterIcon />} />
            {/* <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} /> */}
            </BottomNavigation>
        </Paper>
        </Container>  
                
    );
}

export default Landing;
