import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

// const bull = (
//   <Box
//     component="span"
//     sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
//   >•
//   </Box>
// );

export default function TranscriptionCard({videoUrl, transcript}) {

  return (
    <Card sx={{ width: '100%', maxWidth: "fit-content", margin:"4%" }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Video Summary
        </Typography>
        {/* <Typography variant="h5" component="div">
          Key Points with Time Stamps
        </Typography> */}
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {videoUrl}
        </Typography>
        <Typography variant="body2">
          {transcript}          
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}